import { en } from '@/languages/en';
import { CONTACT_US_PAGE_URL } from '@/utils/urlUi';

const language = en;

export const blogPageContent = {
  header: {
    title: language.blog.header.title
  },
  cta: {
    title: language.blog.cta.title,
    subTitle: language.blog.cta.subTitle,
    button: {
      text: language.blog.cta.button,
      link: CONTACT_US_PAGE_URL,
      isExternal: false
    }
  }
};
