import { memo } from 'react';
import BackgroundImage from '../BackgroundImage';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { CTABackgroundImageType, CTAType } from './types';
import './CTA.scss';

const CTA = ({
  title,
  subTitle,
  buttonConfig,
  backgroundImages,
  backgroundImagesAlt = '',
  description
}: CTAType) => {
  const renderCta = () => (
    <div className="cta cta-background no-global-padding">
      <div className="container-fluid no-gap h-100 text-align-center">
        <div className="row">
          <div className="col">
            <div className={'cta__content-wrapper padding-global'}>
              <Typography
                component="h2"
                typographyKind={'header-secondary-large'}
                color={'neutral-variant-ninety-nine'}
              >
                {title}
              </Typography>
              <Typography
                component="h3"
                typographyKind={'header-secondary-small-medium'}
                color={'neutral-variant-ninety-nine'}
              >
                {subTitle}
              </Typography>
              <Typography
                component="p"
                color="neutral-variant-ninety-nine"
                typographyKind="text-secondary-large-medium"
                size={21}
                lineHeight={28}
              >
                {description}
              </Typography>
              <Button
                color={'secondary'}
                textColor={'secondary-sixty'}
                link={buttonConfig?.link || ''}
                isExternalLink={buttonConfig.isExternal}
                additionalClass={buttonConfig.className}
              >
                {buttonConfig.text}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderCtaWithImage = (backgroundImages: CTABackgroundImageType) => (
    <div className="cta">
      <div className="no-global-padding" />
      <BackgroundImage
        imageUrl={backgroundImages.imageUrl}
        imageMobileUrl={backgroundImages.imageMobileUrl}
        imageDesktopFull={backgroundImages.imageDesktopUrl}
        alt={backgroundImagesAlt}
        additionalClassContainer={'cta__background-img-wrapper'}
      >
        <div className="container-fluid no-gap content--max-width text-align-center">
          <div className="row">
            <div className="col">
              <div className={'cta__content-wrapper-with-image'}>
                <Typography
                  component="h2"
                  typographyKind={'header-secondary-large'}
                  color={'neutral-variant-ninety-nine'}
                >
                  {title}
                </Typography>
                <Typography
                  component="h3"
                  typographyKind={'header-secondary-small-medium'}
                  color={'neutral-variant-ninety-nine'}
                >
                  {subTitle}
                </Typography>
                <Typography
                  component="p"
                  color="neutral-variant-ninety-nine"
                  typographyKind="text-secondary-large-medium"
                  size={20}
                  lineHeight={22}
                >
                  {description}
                </Typography>
                <Button
                  isExternalLink={buttonConfig?.isExternal || false}
                  link={buttonConfig.link}
                  additionalClass={buttonConfig.className}
                >
                  {buttonConfig?.text || ''}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );

  return backgroundImages ? renderCtaWithImage(backgroundImages) : renderCta();
};

export default memo(CTA);
