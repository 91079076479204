import { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { LazyImageType } from '@/components/LazyImage/types';
import { getMarginPaddingClass } from '@/helpers';
import './LazyImage.scss';

const LazyImage = ({
  imageName = '',
  alt = '',
  additionalClass = '',
  padding = [0],
  margin = [0],
  loading = 'lazy',
  imgClassName = '',
  dimension = {}
}: LazyImageType) => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED, blurredOptions: { toFormat: AUTO })
            }
          }
        }
      }
    }
  `);

  const [imageNameValue, setImageNameValue] = useState('');

  const className = `
    image ${additionalClass}
    ${getMarginPaddingClass(padding, margin)}`;

  const getImageFromList = () =>
    (data?.allFile?.edges || []).filter((item: any): IGatsbyImageData => {
      return item?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src.includes(
        imageNameValue
      );
    })?.[0];

  const image = getImage(getImageFromList()?.node);

  useEffect(() => {
    setImageNameValue(imageName);
  }, [imageName]);

  return (
    <>
      {imageNameValue ? (
        <GatsbyImage
          loading={loading}
          imgClassName={`image__img-tag ${imgClassName}`}
          className={className}
          image={
            { ...image, ...(dimension as any) } || ({} as IGatsbyImageData)
          }
          alt={alt || imageName}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default LazyImage;
