import CTA from '@/components/CTA';
import Layout from '@/components/Layout';
import BlogPostsList from '@/sections/Blog/components/BlogPostsList';
import { blogPageContent } from '@/utils/contentData/blogPage';
import { seoConfiguration } from '@/utils/seo-config';
const { title, subTitle, button } = blogPageContent.cta;

const BlogPage = () => {
  const { title: titleSeo, image, description } = seoConfiguration.blog;

  return (
    <Layout image={image} title={titleSeo} description={description}>
      <BlogPostsList />
      <CTA
        title={title}
        subTitle={subTitle}
        buttonConfig={{
          text: button.text,
          link: button.link,
          isExternal: button.isExternal
        }}
      />
    </Layout>
  );
};

export default BlogPage;
