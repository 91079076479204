import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BlogPostType } from './types';
import { Typography } from '@/components/Typography';
import { RightIcon } from '@/static/assets/icons/Navigation';
import './BlogPost.scss';

const BlogPost = ({
  slug,
  date,
  author,
  authorUrl,
  title,
  excerpt,
  featuredImage,
  featuredImageAlt
}: BlogPostType) => (
  <div
    className={'blog-post col-lg-6 col-md-6 col-sm-12 no-gap position-relative'}
  >
    <Link to={slug}>
      <GatsbyImage
        image={featuredImage}
        alt={featuredImageAlt}
        className={'blog-post-image'}
      />
    </Link>
    <Typography
      component={'span'}
      typographyKind={'text-secondary-small'}
      className={'post-data'}
      size={14}
    >
      by <a href={authorUrl}>{author}</a> on {date}
    </Typography>
    <Typography
      component={'h4'}
      margin={[2, 0]}
      typographyKind={'header-secondary-small-bold'}
      link={slug}
    >
      {title}
    </Typography>
    <Typography
      component={'p'}
      margin={[2, 0]}
      typographyKind={'text-primary-medium-regular'}
    >
      {excerpt}
    </Typography>
    <Link
      to={slug}
      className={'flex-row flex-align-items-center blog-post-link-wrapper'}
    >
      <Typography
        component="p"
        typographyKind={'text-primary-medium-bold'}
        className={'blog-post-link'}
      >
        Read more
        <RightIcon />
      </Typography>
    </Link>
  </div>
);

export default BlogPost;
