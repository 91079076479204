import { memo } from 'react';
import { BackgroundImageType } from '@/components/BackgroundImage/type';
import LazyImage from '@/components/LazyImage';
import { useBackgroundImage } from '@/hooks/useBackgroundImage';
import './BackgroundImage.scss';

const BackgroundImage = ({
  children,
  imageUrl = '',
  imageTabletUrl = '',
  imageMobileUrl = '',
  imageDesktopFull = '',
  alt = '',
  additionalClassContainer = '',
  additionalClassImage = ''
}: BackgroundImageType) => {
  const { backgroundImage } = useBackgroundImage(
    imageUrl,
    imageTabletUrl,
    imageMobileUrl,
    imageDesktopFull
  );

  return (
    <div
      className={`background-image no-global-padding ${additionalClassContainer}`}
    >
      <LazyImage
        imageName={backgroundImage}
        alt={alt}
        additionalClass={additionalClassImage}
        imgClassName={`background-image__img ${additionalClassImage}`}
      />
      <div className={'background-image__text-content-wrapper padding-global'}>
        {children}
      </div>
    </div>
  );
};

export default memo(BackgroundImage);
