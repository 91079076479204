import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { POSTS_TO_FETCH } from '../../utils';
import { Button } from '@/components/Button';
import { Typography } from '@/components/Typography';
import { useBlogPostsQuery } from '@/hooks/useBlogPostsQuery';
import BlogPost from '@/sections/Blog/components/BlogPostsList/BlogPost';
import { blogPageContent } from '@/utils/contentData/blogPage';

const { title } = blogPageContent.header;

const BlogPostsList = () => {
  const { postsList, hasMoreToLoad, handleLoadMore } =
    useBlogPostsQuery(POSTS_TO_FETCH);

  const getBlogPosts = () =>
    postsList &&
    postsList.map(
      (
        {
          slug,
          excerpt,
          frontmatter: {
            id,
            date,
            author,
            title,
            authorProfileUrl,
            featuredImageData,
            featuredImageAlt
          }
        },
        index: number
      ) => {
        const featuredImage = getImage(featuredImageData);

        return (
          <BlogPost
            key={`post-${id} + ${index}`}
            date={date}
            author={author}
            authorUrl={authorProfileUrl}
            slug={slug}
            excerpt={excerpt}
            title={title}
            featuredImage={featuredImage as IGatsbyImageData}
            featuredImageAlt={featuredImageAlt}
          />
        );
      }
    );

  return (
    <>
      <div className={'content--max-width'}>
        <Typography
          component={'h2'}
          typographyKind={'header-primary-medium-bold'}
          margin={[16, 0]}
        >
          {title}
        </Typography>
        <div className={'container-fluid'}>
          <div className={'row'}>{getBlogPosts()}</div>
        </div>
      </div>
      <div className={'flex justify-content-center'}>
        {hasMoreToLoad && (
          <Button
            outlineColor={'primary'}
            onClickFunc={handleLoadMore}
            margin={[16, 0]}
          >
            Load more
          </Button>
        )}
      </div>
    </>
  );
};

export default BlogPostsList;
