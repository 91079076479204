import { useEffect, useState } from 'react';
import { useBreakpoints } from '@/hooks/useBreakpoints';

export const useBackgroundImage = (
  imageUrl: string,
  imageTabletUrl: string,
  imageMobileUrl: string,
  imageDesktopFullHD: string
) => {
  const [backgroundImage, setBackgroundImage] = useState(imageUrl);

  const { isMobile, isTablet, isBreakpointRange } = useBreakpoints();

  const isDesktopBig = isBreakpointRange('desktopBig', 'widthFull');

  const setBackgroundImageUrl = () => {
    if (isMobile) {
      setBackgroundImage(imageMobileUrl || imageTabletUrl || imageUrl);
    } else if (isTablet) {
      setBackgroundImage(imageTabletUrl || imageUrl);
    } else if (isDesktopBig) {
      setBackgroundImage(imageDesktopFullHD || imageUrl);
    } else {
      setBackgroundImage(imageUrl);
    }
  };

  useEffect(() => {
    setBackgroundImageUrl();
  }, [isMobile, isTablet, isDesktopBig]);

  return { backgroundImage };
};
